import css from 'styled-jsx/css'

export const SelectStyle = css.global`
  .ant-select-single .ant-select-selector {
    display: flex;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    position: absolute;
    top: 0;
    right: 11px;
    bottom: 0;
    left: 11px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
    width: 100%;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 30px;
    transition: all 0.3s;
  }
  @supports (-moz-appearance: meterbar) {
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 30px;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    position: relative;
    user-select: none;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    pointer-events: none;
  }
  .ant-select-single .ant-select-selector::after,
  .ant-select-single .ant-select-selector .ant-select-selection-item::after,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: '\a0';
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 25px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: #bfbfbf;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 32px;
    padding: 0 11px;
    font-size: 16px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: pointer;
  }
  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    cursor: text;
  }
  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: auto;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    cursor: not-allowed;
  }
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: not-allowed;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    appearance: none;
  }
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input::-webkit-search-cancel-button {
    display: none;
    -webkit-appearance: none;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 30px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
    line-height: 30px;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector::after {
    display: none;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
    position: static;
    width: 100%;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
    position: absolute;
    right: 0;
    left: 0;
    padding: 0 11px;
  }
  .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
    display: none;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 38px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 38px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    height: 24px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 22px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
    height: 22px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
    right: 7px;
    left: 7px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 7px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
    right: 28px;
  }
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 21px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px;
  }

  .ant-select-multiple .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 1px 4px;
    font-size: 16px;
  }
  .ant-select-multiple .ant-select-selector input {
    cursor: pointer;
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector {
    cursor: text;
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector input {
    cursor: auto;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    cursor: not-allowed;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selector input {
    cursor: not-allowed;
  }
  .ant-select-multiple .ant-select-selector .ant-select-selection-search-input {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    appearance: none;
  }
  .ant-select-multiple .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
    display: none;
    -webkit-appearance: none;
  }
  .ant-select-show-search.ant-select-multiple .ant-select-selector {
    cursor: text;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selector {
    background: #f5f5f5;
    cursor: not-allowed;
  }
  .ant-select-multiple .ant-select-selector::after {
    display: inline-block;
    width: 0;
    margin: 2px 0;
    line-height: 24px;
    content: '\a0';
  }
  .ant-select-multiple.ant-select-show-arrow .ant-select-selector,
  .ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-right: 24px;
  }
  .ant-select-multiple .ant-select-selection-item {
    position: relative;
    display: flex;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    height: 24px;
    margin-top: 2px;
    margin-right: 4px;
    margin-bottom: 2px;
    padding: 0 4px 0 8px;
    line-height: 22px;
    background: #f5f5f5;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    font-size: 16px;
    cursor: default;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    user-select: none;
  }
  .ant-select-disabled.ant-select-multiple .ant-select-selection-item {
    color: #bfbfbf;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-select-multiple .ant-select-selection-item-content {
    display: inline-block;
    margin-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-select-multiple .ant-select-selection-item-remove {
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.45);
    font-weight: bold;
    font-size: 12px;
    line-height: inherit;
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
  }
  .ant-select-multiple .ant-select-selection-item-remove > * {
    line-height: 1;
  }
  .ant-select-multiple .ant-select-selection-item-remove svg {
    display: inline-block;
  }
  .ant-select-multiple .ant-select-selection-item-remove::before {
    display: none;
  }
  .ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
    display: block;
  }
  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: -0.2em;
  }
  .ant-select-multiple .ant-select-selection-item-remove:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .ant-select-multiple .ant-select-selection-search {
    position: relative;
    margin-left: 0.5px;
  }
  .ant-select-multiple .ant-select-selection-search-input,
  .ant-select-multiple .ant-select-selection-search-mirror {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 1.5715;
    transition: all 0.3s;
  }
  .ant-select-multiple .ant-select-selection-search-input {
    width: 100%;
    min-width: 4.1px;
  }
  .ant-select-multiple .ant-select-selection-search-mirror {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    white-space: nowrap;
    visibility: hidden;
  }
  .ant-select-multiple .ant-select-selection-search:first-child .ant-select-selection-search-input {
    margin-left: 6.5px;
  }
  .ant-select-multiple .ant-select-selection-placeholder {
    position: absolute;
    top: 50%;
    right: 11px;
    left: 11px;
    transform: translateY(-50%);
    transition: all 0.3s;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selector::after {
    line-height: 32px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 32px;
    line-height: 30px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-search {
    height: 33px;
    line-height: 33px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
  .ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
    height: 32px;
    line-height: 30px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selector::after {
    line-height: 16px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 16px;
    line-height: 14px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search {
    height: 17px;
    line-height: 17px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
  .ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
    height: 16px;
    line-height: 14px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
    left: 7px;
  }
  .ant-select-multiple.ant-select-sm .ant-select-selection-search:first-child .ant-select-selection-search-input {
    margin-left: 3px;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: 32px;
    line-height: 32px;
  }
  .ant-select-disabled .ant-select-selection-item-remove {
    display: none;
  }
  /* Reset search input style */
  .ant-select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-select-selection-item {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  @media all and (-ms-high-contrast: none) {
    .ant-select-selection-item *::-ms-backdrop,
    .ant-select-selection-item {
      flex: auto;
    }
  }
  .ant-select-selection-placeholder {
    flex: 1;
    overflow: hidden;
    color: #bfbfbf;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  @media all and (-ms-high-contrast: none) {
    .ant-select-selection-placeholder *::-ms-backdrop,
    .ant-select-selection-placeholder {
      flex: auto;
    }
  }
  .ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 53%;
    right: 11px;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
  }
  .ant-select-arrow > * {
    line-height: 1;
  }
  .ant-select-arrow svg {
    display: inline-block;
  }
  .ant-select-arrow::before {
    display: none;
  }
  .ant-select-arrow .ant-select-arrow-icon {
    display: block;
  }
  .ant-select-arrow .anticon {
    vertical-align: top;
    transition: transform 0.3s;
  }
  .ant-select-arrow .anticon > svg {
    vertical-align: top;
  }
  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: auto;
  }
  .ant-select-disabled .ant-select-arrow {
    cursor: not-allowed;
  }
  .ant-select-clear {
    position: absolute;
    top: 50%;
    right: 11px;
    z-index: 1;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    background: #fff;
    cursor: pointer;
    opacity: 0;
    transition: color 0.3s ease, opacity 0.15s ease;
    text-rendering: auto;
  }
  .ant-select-clear::before {
    display: block;
  }
  .ant-select-clear:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-select:hover .ant-select-clear {
    opacity: 1;
  }
  .ant-select-dropdown {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    box-sizing: border-box;
    padding: 4px 0;
    overflow: hidden;
    font-size: 14px;
    font-variant: initial;
    background-color: #fff;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
  .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
    animation-name: antSlideUpIn;
  }
  .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
  .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
    animation-name: antSlideDownIn;
  }
  .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
    animation-name: antSlideUpOut;
  }
  .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
    animation-name: antSlideDownOut;
  }
  .ant-select-dropdown-hidden {
    display: none;
  }
  .ant-select-dropdown-empty {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-item-empty {
    position: relative;
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-select-item {
    position: relative;
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  .ant-select-item-group {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    cursor: default;
  }
  .ant-select-item-option {
    display: flex;
  }
  .ant-select-item-option-content {
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ant-select-item-option-state {
    flex: none;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #f5f5f5;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: #e6f7ff;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: #1890ff;
  }
  .ant-select-item-option-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-select-item-option-grouped {
    padding-left: 24px;
  }
  .ant-select-lg {
    font-size: 16px;
  }
  .ant-select-borderless .ant-select-selector {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-select-rtl {
    direction: rtl;
  }
  .ant-select-rtl .ant-select-arrow {
    right: initial;
    left: 11px;
  }
  .ant-select-rtl .ant-select-clear {
    right: initial;
    left: 11px;
  }
  .ant-select-dropdown-rtl {
    direction: rtl;
  }
  .ant-select-dropdown-rtl .ant-select-item-option-grouped {
    padding-right: 24px;
    padding-left: 12px;
  }
  .ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
  .ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-right: 4px;
    padding-left: 24px;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-item {
    margin-right: 0;
    margin-left: 4px;
    padding: 0 8px 0 4px;
    text-align: right;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
    margin-right: 0;
    margin-left: 4px;
    text-align: right;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-search {
    margin-right: 0.5px;
    margin-left: 4px;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
    right: 0;
    left: auto;
  }
  .ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
    right: 11px;
    left: auto;
  }
  .ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
    right: 7px;
  }
  .ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    right: 0;
    left: 9px;
    text-align: right;
  }
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    right: 11px;
    left: 25px;
  }
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0;
    padding-left: 18px;
  }
  .ant-select-rtl.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 11px;
  }
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
    right: 0;
  }
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
  .ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0;
    padding-left: 21px;
  }
  .ant-spin {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    display: none;
    color: #1890ff;
    text-align: center;
    vertical-align: middle;
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ant-spin-spinning {
    position: static;
    display: inline-block;
    opacity: 1;
  }
  .ant-spin-nested-loading {
    position: relative;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px;
  }
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px;
  }
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
    position: absolute;
    top: 50%;
    width: 100%;
    padding-top: 5px;
    text-shadow: 0 1px 2px #fff;
  }
  .ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
    margin-top: -20px;
  }
  .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
    margin: -7px;
  }
  .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
    padding-top: 2px;
  }
  .ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
    margin-top: -17px;
  }
  .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
    margin: -16px;
  }
  .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
    padding-top: 11px;
  }
  .ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
    margin-top: -26px;
  }
  .ant-spin-container {
    position: relative;
    transition: opacity 0.3s;
  }
  .ant-spin-container::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: none \9;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    transition: all 0.3s;
    content: '';
    pointer-events: none;
  }
  .ant-spin-blur {
    clear: both;
    overflow: hidden;
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  }
  .ant-spin-blur::after {
    opacity: 0.4;
    pointer-events: auto;
  }
  .ant-spin-tip {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-spin-dot {
    position: relative;
    display: inline-block;
    font-size: 20px;
    width: 1em;
    height: 1em;
  }
  .ant-spin-dot-item {
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    background-color: #1890ff;
    border-radius: 100%;
    transform: scale(0.75);
    transform-origin: 50% 50%;
    opacity: 0.3;
    animation: antSpinMove 1s infinite linear alternate;
  }
  .ant-spin-dot-item:nth-child(1) {
    top: 0;
    left: 0;
  }
  .ant-spin-dot-item:nth-child(2) {
    top: 0;
    right: 0;
    animation-delay: 0.4s;
  }
  .ant-spin-dot-item:nth-child(3) {
    right: 0;
    bottom: 0;
    animation-delay: 0.8s;
  }
  .ant-spin-dot-item:nth-child(4) {
    bottom: 0;
    left: 0;
    animation-delay: 1.2s;
  }
  .ant-spin-dot-spin {
    transform: rotate(45deg);
    animation: antRotate 1.2s infinite linear;
  }
  .ant-spin-sm .ant-spin-dot {
    font-size: 14px;
  }
  .ant-spin-sm .ant-spin-dot i {
    width: 6px;
    height: 6px;
  }
  .ant-spin-lg .ant-spin-dot {
    font-size: 32px;
  }
  .ant-spin-lg .ant-spin-dot i {
    width: 14px;
    height: 14px;
  }
  .ant-spin.ant-spin-show-text .ant-spin-text {
    display: block;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ */
    .ant-spin-blur {
      background: #fff;
      opacity: 0.5;
    }
  }
  @keyframes antSpinMove {
    to {
      opacity: 1;
    }
  }
  @keyframes antRotate {
    to {
      transform: rotate(405deg);
    }
  }
  .ant-spin-rtl {
    direction: rtl;
  }
  .ant-spin-rtl .ant-spin-dot-spin {
    transform: rotate(-45deg);
    animation-name: antRotateRtl;
  }
  @keyframes antRotateRtl {
    to {
      transform: rotate(-405deg);
    }
  }
`

import React from 'react'
import { Select as ANTDSelect } from 'antd'
import { SelectStyle } from 'src/@legacy/@core/style/styled/antd/Select.style'
import { SelectProps, SelectValue } from 'antd/lib/select'

export const Select = <T extends SelectValue = SelectValue>(props: SelectProps<T> | { parentPopup: boolean }) => (
  <>
    <style jsx global>
      {SelectStyle}
    </style>
    <ANTDSelect
      //@ts-ignore
      {...(props.parentPopup
        ? {
            getPopupContainer: (triggerNode) => {
              return triggerNode.parentElement
            }
          }
        : {})}
      {...props}
    />
  </>
)
Select.Option = ANTDSelect.Option
Select.OptGroup = ANTDSelect.OptGroup

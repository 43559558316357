import css from 'styled-jsx/css'

export const SkeletonStyle = css.global`
  .ant-skeleton {
    display: table;
    width: 100%;
  }
  .ant-skeleton-header {
    display: table-cell;
    padding-right: 16px;
    vertical-align: top;
  }
  .ant-skeleton-header .ant-skeleton-avatar {
    display: inline-block;
    vertical-align: top;
    background: #f2f2f2;
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
  .ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .ant-skeleton-header .ant-skeleton-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .ant-skeleton-header .ant-skeleton-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .ant-skeleton-content {
    display: table-cell;
    width: 100%;
    vertical-align: top;
  }
  .ant-skeleton-content .ant-skeleton-title {
    width: 100%;
    height: 16px;
    margin-top: 16px;
    background: #f2f2f2;
  }
  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 24px;
  }
  .ant-skeleton-content .ant-skeleton-paragraph {
    padding: 0;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li {
    width: 100%;
    height: 16px;
    list-style: none;
    background: #f2f2f2;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
    width: 61%;
  }
  .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 16px;
  }
  .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
    margin-top: 12px;
  }
  .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 28px;
  }
  .ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
  .ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
    border-radius: 100px;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-button {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-input {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-image {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;
  }
  .ant-skeleton-element {
    display: inline-block;
    width: auto;
  }
  .ant-skeleton-element .ant-skeleton-button {
    display: inline-block;
    vertical-align: top;
    background: #f2f2f2;
    border-radius: 2px;
    width: 64px;
    height: 32px;
    line-height: 32px;
  }
  .ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
    width: 32px;
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
    border-radius: 32px;
  }
  .ant-skeleton-element .ant-skeleton-button-lg {
    width: 80px;
    height: 40px;
    line-height: 40px;
  }
  .ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
    width: 40px;
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
    border-radius: 40px;
  }
  .ant-skeleton-element .ant-skeleton-button-sm {
    width: 48px;
    height: 24px;
    line-height: 24px;
  }
  .ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
    width: 24px;
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
    border-radius: 24px;
  }
  .ant-skeleton-element .ant-skeleton-avatar {
    display: inline-block;
    vertical-align: top;
    background: #f2f2f2;
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
  .ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-input {
    display: inline-block;
    vertical-align: top;
    background: #f2f2f2;
    width: 100%;
    height: 32px;
    line-height: 32px;
  }
  .ant-skeleton-element .ant-skeleton-input-lg {
    width: 100%;
    height: 40px;
    line-height: 40px;
  }
  .ant-skeleton-element .ant-skeleton-input-sm {
    width: 100%;
    height: 24px;
    line-height: 24px;
  }
  .ant-skeleton-element .ant-skeleton-image {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    background: #f2f2f2;
    width: 96px;
    height: 96px;
    line-height: 96px;
  }
  .ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
    border-radius: 50%;
  }
  .ant-skeleton-element .ant-skeleton-image-path {
    fill: #bfbfbf;
  }
  .ant-skeleton-element .ant-skeleton-image-svg {
    width: 48px;
    height: 48px;
    line-height: 48px;
    max-width: 192px;
    max-height: 192px;
  }
  .ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
    border-radius: 50%;
  }
  @keyframes ant-skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  .ant-skeleton-rtl {
    direction: rtl;
  }
  .ant-skeleton-rtl .ant-skeleton-header {
    padding-right: 0;
    padding-left: 16px;
  }
  .ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
  .ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
    animation-name: ant-skeleton-loading-rtl;
  }
  .ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
    animation-name: ant-skeleton-loading-rtl;
  }
  @keyframes ant-skeleton-loading-rtl {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
`

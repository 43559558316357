import { InputNumberProps } from 'antd/lib/input-number'
import { InputNumber as ANTDInputNumber } from 'antd'
import React, { FC } from 'react'
import { InputNumberStyle } from 'src/@legacy/@core/style/styled/antd/InputNumber.style'
const InputNumber: FC<InputNumberProps> = (props) => (
  <>
    <style jsx global>
      {InputNumberStyle}
    </style>
    <ANTDInputNumber {...props} />
  </>
)

export { InputNumber }

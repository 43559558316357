import React, { FC } from 'react'
import { Card as ANTDCard } from 'antd'
import { CardProps } from 'antd/lib/card'
import { CardStyle } from 'src/@legacy/@core/style/styled/antd/Card.style'

export const Card: FC<CardProps & React.RefAttributes<HTMLDivElement>> = (props) => (
  <>
    <style jsx global>
      {CardStyle}
    </style>
    <ANTDCard {...props} />
  </>
)

import React, { FC } from 'react'
import { Space as ANTDSpace } from 'antd'
import { SpaceProps } from 'antd/lib/space'
import { SpaceStyle } from 'src/@legacy/@core/style/styled/antd/Space.style'

export const Space: FC<SpaceProps> = (props) => (
  <>
    <style jsx global>
      {SpaceStyle}
    </style>
    <ANTDSpace {...props} />
  </>
)

import React, { FC } from 'react'
import { Typography as ANTDTypography } from 'antd'
import { TypographyProps } from 'antd/lib/typography'
import { TextProps } from 'antd/lib/typography/Text'

export const Typography: TypographyProps & {
  Text: FC<
    | TextProps
    | {
        size: 'small' | 'medium' | 'large'
        color: 'primary' | 'link' | 'white' | 'gray' | string
        decoration: 'underline' | '' | ''
        nowrap?: boolean
      }
  >
} = ANTDTypography

Typography.Text = (props) => {
  const { size, className, color, decoration, children, strong, nowrap } = props
  const classNameMap = { small: 'text__12', medium: 'text__14', large: 'text__16' }[size || 'medium']
  const classNamColor = {
    primary: 'text__color-primary',
    link: 'text__color-link',
    white: 'text__color-white',
    gray: 'text__color-gray'
  }[color]
  const classNameDecorate = {
    underline: 'text__decore-underline'
  }[decoration]
  return (
    <span
      style={{ ...(classNamColor ? {} : { color }), ...(nowrap ? { whiteSpace: 'nowrap' } : {}) }}
      className={`text ${classNameMap} ${classNamColor || ''} ${classNameDecorate || ''} ${className || ''}`}>
      {strong ? <strong>{children}</strong> : children}
    </span>
  )
}

export default Typography

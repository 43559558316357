import css from 'styled-jsx/css'

export const TableStyle = css.global`
  .ant-table.ant-table-middle .ant-table-title,
  .ant-table.ant-table-middle .ant-table-footer,
  .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td,
  .ant-table.ant-table-middle tfoot > tr > th,
  .ant-table.ant-table-middle tfoot > tr > td {
    padding: 12px 8px;
  }
  .ant-table.ant-table-middle .ant-table-thead th.ant-table-column-has-sorters {
    padding: 0;
  }
  .ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column {
    margin: -12px -8px;
  }
  .ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column-title {
    padding: 12px 2.3em 12px 8px;
  }
  .ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
    padding: 12px 8px;
  }
  .ant-table.ant-table-middle .ant-table-expanded-row-fixed {
    margin: -12px -8px;
  }
  .ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: -12px -8px -12px 25px;
  }
  .ant-table.ant-table-small {
    font-size: 14px;
  }
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 8px 8px;
  }
  .ant-table.ant-table-small .ant-table-thead th.ant-table-column-has-sorters {
    padding: 0;
  }
  .ant-table.ant-table-small .ant-table-thead .ant-table-filter-column {
    margin: -8px -8px;
  }
  .ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
    padding: 8px 2.3em 8px 8px;
  }
  .ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
    padding: 8px 8px;
  }
  .ant-table.ant-table-small .ant-table-expanded-row-fixed {
    margin: -8px -8px;
  }
  .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: -8px -8px -8px 25px;
  }
  .ant-table-small .ant-table-thead > tr > th {
    background-color: #fafafa;
  }
  .ant-table-small .ant-table-selection-column {
    width: 46px;
    min-width: 46px;
  }
  .ant-table.ant-table-bordered > .ant-table-title {
    border: 1px solid #f0f0f0;
    border-bottom: 0;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border: 1px solid #f0f0f0;
    border-right: 0;
    border-bottom: 0;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
    border-right: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
    border-right: 1px solid #f0f0f0;
  }
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
    margin: -16px -17px;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
    position: absolute;
    top: 0;
    right: 1px;
    bottom: 0;
    border-right: 1px solid #f0f0f0;
    content: '';
  }
  .ant-table.ant-table-bordered.ant-table-scroll-horizontal
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr.ant-table-expanded-row
    > td,
  .ant-table.ant-table-bordered.ant-table-scroll-horizontal
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr.ant-table-placeholder
    > td {
    border-right: 0;
  }
  .ant-table.ant-table-bordered.ant-table-middle
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed,
  .ant-table.ant-table-bordered.ant-table-middle
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed {
    margin: -12px -9px;
  }
  .ant-table.ant-table-bordered.ant-table-small
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed,
  .ant-table.ant-table-bordered.ant-table-small
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed {
    margin: -8px -9px;
  }
  .ant-table.ant-table-bordered > .ant-table-footer {
    border: 1px solid #f0f0f0;
    border-top: 0;
  }
  .ant-table-cell .ant-table-container:first-child {
    border-top: 0;
  }
  .ant-table-cell-scrollbar {
    box-shadow: 0 1px 0 1px #fafafa;
  }
  .ant-table-wrapper {
    clear: both;
    max-width: 100%;
  }
  .ant-table-wrapper::before {
    display: table;
    content: '';
  }
  .ant-table-wrapper::after {
    display: table;
    clear: both;
    content: '';
  }
  .ant-table-wrapper::before {
    display: table;
    content: '';
  }
  .ant-table-wrapper::after {
    display: table;
    clear: both;
    content: '';
  }
  .ant-table {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    z-index: 0;
    font-size: 14px;
    background: #fff;
    border-radius: 2px;
  }
  .ant-table table {
    width: 100%;
    text-align: left;
    border-radius: 2px 2px 0 0;
    border-collapse: separate;
    border-spacing: 0;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    position: relative;
    padding: 16px 16px;
    overflow-wrap: break-word;
  }
  .ant-table-cell-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
  .ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
    overflow: visible;
  }
  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
  .ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-table-title {
    padding: 16px 16px;
  }
  .ant-table-footer {
    padding: 16px 16px;
    color: rgba(0, 0, 0, 0.85);
    background: #fafafa;
  }
  .ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
  }
  .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
    text-align: center;
  }
  .ant-table-thead > tr:not(:last-child) > th[colspan] {
    border-bottom: 0;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;
  }
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: -16px -16px -16px 33px;
  }
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
    border-bottom: 0;
  }
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
    border-radius: 0;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #e6f7ff;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #e6f7ff;
    border-color: rgba(0, 0, 0, 0.03);
  }
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: #dcf4ff;
  }
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    border-bottom: 1px solid #f0f0f0;
  }
  .ant-table-pagination.ant-pagination {
    /* margin: 16px 0; */
  }
  .ant-table-pagination {
    display: flex;
  }
  .ant-table-pagination-left {
    justify-content: flex-start;
  }
  .ant-table-pagination-center {
    justify-content: center;
  }
  .ant-table-pagination-right {
    justify-content: flex-end;
  }
  .ant-table-thead th.ant-table-column-has-sorters {
    padding: 0;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #f2f2f2;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
    background: #f7f7f7;
  }
  .ant-table-thead th.ant-table-column-sort {
    background: #f5f5f5;
  }
  td.ant-table-column-sort {
    background: #fafafa;
  }
  .ant-table-column-sorters-with-tooltip {
    display: inline-block;
    width: 100%;
  }
  .ant-table-column-sorters {
    display: inline-flex;
    align-items: center;
    padding: 16px 16px;
  }
  .ant-table-column-sorter {
    margin-top: 0.15em;
    margin-bottom: -0.15em;
    margin-left: 8px;
    color: #bfbfbf;
  }
  .ant-table-column-sorter-full {
    margin-top: -0.2em;
    margin-bottom: 0;
  }
  .ant-table-column-sorter-inner {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    display: inline-block;
    font-size: 11px;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #1890ff;
  }
  .ant-table-column-sorter-up + .ant-table-column-sorter-down {
    margin-top: -0.3em;
  }
  .ant-table-filter-column {
    display: flex;
    align-items: center;
    margin: -16px -16px;
  }
  .ant-table-filter-column-title {
    flex: auto;
    padding: 16px 2.3em 16px 16px;
  }
  .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column {
    margin: 0;
  }
  .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
    padding: 0 2.3em 0 0;
  }
  .ant-table-filter-trigger-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex: none;
    align-items: stretch;
    align-self: stretch;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .ant-table-filter-trigger-container-open,
  .ant-table-filter-trigger-container:hover,
  .ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
    background: #e5e5e5;
  }
  .ant-table-filter-trigger {
    display: block;
    width: 2.3em;
    color: #bfbfbf;
    font-size: 12px;
    transition: color 0.3s;
  }
  .ant-table-filter-trigger .anticon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ant-table-filter-trigger-container-open .ant-table-filter-trigger,
  .ant-table-filter-trigger:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-table-filter-trigger.active {
    color: #1890ff;
  }
  .ant-table-filter-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    min-width: 120px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-table-filter-dropdown .ant-dropdown-menu {
    max-height: 264px;
    overflow-x: hidden;
    border: 0;
    box-shadow: none;
  }
  .ant-table-filter-dropdown-submenu > ul {
    max-height: calc(100vh - 130px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
  .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
    padding-left: 8px;
  }
  .ant-table-filter-dropdown-btns {
    display: flex;
    justify-content: space-between;
    padding: 7px 8px 7px 3px;
    overflow: hidden;
    background-color: inherit;
    border-top: 1px solid #f0f0f0;
  }
  .ant-table .ant-table-selection-col {
    width: 60px;
  }
  table tr th.ant-table-selection-column,
  table tr td.ant-table-selection-column {
    padding-right: 8px;
    padding-left: 8px;
    text-align: center;
  }
  table tr th.ant-table-selection-column .ant-radio-wrapper,
  table tr td.ant-table-selection-column .ant-radio-wrapper {
    margin-right: 0;
  }
  .ant-table-selection {
    position: relative;
  }
  .ant-table-selection-extra {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-table-selection-extra .anticon {
    display: inline-block;
    font-size: 10px;
    color: #bfbfbf;
  }
  .ant-table-selection-extra .anticon:hover {
    color: #a6a6a6;
  }
  .ant-table-expand-icon-col {
    width: 48px;
  }
  .ant-table-row-expand-icon-cell {
    text-align: center;
  }
  .ant-table-row-indent {
    float: left;
    height: 1px;
  }
  .ant-table-row-expand-icon {
    color: #1890ff;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s;
    position: relative;
    display: inline-flex;
    float: left;
    box-sizing: border-box;
    width: 17px;
    height: 17px;
    padding: 0;
    color: inherit;
    line-height: 17px;
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    outline: none;
    transition: all 0.3s;
    user-select: none;
  }
  .ant-table-row-expand-icon:focus,
  .ant-table-row-expand-icon:hover {
    color: #40a9ff;
  }
  .ant-table-row-expand-icon:active {
    color: #096dd9;
  }
  .ant-table-row-expand-icon:focus,
  .ant-table-row-expand-icon:hover,
  .ant-table-row-expand-icon:active {
    border-color: currentColor;
  }
  .ant-table-row-expand-icon::before,
  .ant-table-row-expand-icon::after {
    position: absolute;
    background: currentColor;
    transition: transform 0.3s ease-out;
    content: '';
  }
  .ant-table-row-expand-icon::before {
    top: 7px;
    right: 3px;
    left: 3px;
    height: 1px;
  }
  .ant-table-row-expand-icon::after {
    top: 3px;
    bottom: 3px;
    left: 7px;
    width: 1px;
    transform: rotate(90deg);
  }
  .ant-table-row-expand-icon-collapsed::before {
    transform: rotate(-180deg);
  }
  .ant-table-row-expand-icon-collapsed::after {
    transform: rotate(0deg);
  }
  .ant-table-row-expand-icon-spaced {
    background: transparent;
    border: 0;
    visibility: hidden;
  }
  .ant-table-row-expand-icon-spaced::before,
  .ant-table-row-expand-icon-spaced::after {
    display: none;
    content: none;
  }
  .ant-table-row-indent + .ant-table-row-expand-icon {
    margin-top: 2.5005px;
    margin-right: 8px;
  }
  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: #fbfbfb;
  }
  tr.ant-table-expanded-row .ant-descriptions-view table {
    width: auto;
  }
  .ant-table .ant-table-expanded-row-fixed {
    position: relative;
    margin: -16px -16px;
    padding: 16px 16px;
  }
  .ant-table-tbody > tr.ant-table-placeholder {
    text-align: center;
  }
  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: #fff;
  }
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    position: -webkit-sticky !important;
    position: sticky !important;
    z-index: 2;
    background: #fff;
  }
  .ant-table-cell-fix-left-first::after,
  .ant-table-cell-fix-left-last::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -1px;
    width: 30px;
    transform: translateX(100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
  .ant-table-cell-fix-right-first::after,
  .ant-table-cell-fix-right-last::after {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    width: 30px;
    transform: translateX(-100%);
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
  .ant-table .ant-table-container::before,
  .ant-table .ant-table-container::after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 30px;
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
  }
  .ant-table .ant-table-container::before {
    left: 0;
  }
  .ant-table .ant-table-container::after {
    right: 0;
  }
  .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container {
    position: relative;
  }
  .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .ant-table-ping-left .ant-table-cell-fix-left-first::after,
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container {
    position: relative;
  }
  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after,
  .ant-table-ping-right .ant-table-cell-fix-right-last::after {
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
  }
  .ant-table-sticky-header {
    position: sticky;
    z-index: calc(2 + 1);
  }
  .ant-table-sticky-scroll {
    position: fixed;
    bottom: 0;
    z-index: calc(2 + 1);
    display: flex;
    align-items: center;
    background: #ffffff;
    border-top: 1px solid #f0f0f0;
    opacity: 0.6;
  }
  .ant-table-sticky-scroll:hover {
    transform-origin: center bottom;
  }
  .ant-table-sticky-scroll-bar {
    height: 8px;
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 4px;
  }
  .ant-table-sticky-scroll-bar:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .ant-table-sticky-scroll-bar-active {
    background-color: rgba(0, 0, 0, 0.8);
  }
  @media all and (-ms-high-contrast: none) {
    .ant-table-ping-left .ant-table-cell-fix-left-last::after {
      box-shadow: none !important;
    }
    .ant-table-ping-right .ant-table-cell-fix-right-first::after {
      box-shadow: none !important;
    }
  }
  .ant-table {
    /* title + table */
    /* table */
    /* table + footer */
  }
  .ant-table-title {
    border-radius: 2px 2px 0 0;
  }
  .ant-table-title + .ant-table-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
    border-radius: 0;
  }
  .ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
    border-radius: 0;
  }
  .ant-table-container {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 2px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 2px;
  }
  .ant-table-footer {
    border-radius: 0 0 2px 2px;
  }
  .ant-table-wrapper-rtl {
    direction: rtl;
  }
  .ant-table-rtl {
    direction: rtl;
  }
  .ant-table-wrapper-rtl .ant-table table {
    text-align: right;
  }
  .ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
    text-align: center;
  }
  .ant-table-wrapper-rtl .ant-table-thead > tr > th {
    text-align: right;
  }
  .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
    margin: -16px 33px -16px -16px;
  }
  .ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
    justify-content: flex-end;
  }
  .ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
    justify-content: flex-start;
  }
  .ant-table-wrapper-rtl .ant-table-column-sorter {
    margin-right: 8px;
    margin-left: 0;
  }
  .ant-table-wrapper-rtl .ant-table-filter-column-title {
    padding: 16px 16px 16px 2.3em;
  }
  .ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
    padding: 0 0 0 2.3em;
  }
  .ant-table-wrapper-rtl .ant-table-filter-trigger-container {
    right: auto;
    left: 0;
  }
  .ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
  .ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
  .ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
  .ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
    padding-right: 8px;
    padding-left: 0;
  }
  .ant-table-wrapper-rtl .ant-table-selection {
    text-align: center;
  }
  .ant-table-wrapper-rtl .ant-table-selection-extra {
    right: auto;
    left: 0;
  }
  .ant-table-wrapper-rtl .ant-table-row-indent {
    float: right;
  }
  .ant-table-wrapper-rtl .ant-table-row-expand-icon {
    float: right;
  }
  .ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
    transform: rotate(-90deg);
  }
  .ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
    transform: rotate(180deg);
  }
  .ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
    transform: rotate(0deg);
  }

  .ant-pagination {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
  }
  .ant-pagination ul,
  .ant-pagination ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .ant-pagination::after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: ' ';
  }
  .ant-pagination-total-text {
    display: inline-block;
    height: 32px;
    margin-right: 8px;
    line-height: 30px;
    vertical-align: middle;
  }
  .ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: 0;
    cursor: pointer;
    user-select: none;
  }
  .ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: rgba(0, 0, 0, 0.85);
    transition: none;
  }
  .ant-pagination-item a:hover {
    text-decoration: none;
  }
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: #1890ff;
    transition: all 0.3s;
  }
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: #1890ff;
  }
  .ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: #1890ff;
  }
  .ant-pagination-item-active a {
    color: #1890ff;
  }
  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover {
    border-color: #40a9ff;
  }
  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a {
    color: #40a9ff;
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    outline: 0;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container,
  .ant-pagination-jump-next .ant-pagination-item-container {
    position: relative;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #1890ff;
    font-size: 12px;
    letter-spacing: -1px;
    opacity: 0;
    transition: all 0.2s;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    color: rgba(0, 0, 0, 0.25);
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
    text-align: center;
    text-indent: 0.13em;
    opacity: 1;
    transition: all 0.2s;
  }
  .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
  .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
  .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
    opacity: 1;
  }
  .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
  .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
    opacity: 0;
  }
  .ant-pagination-prev,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin-right: 8px;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    font-family: Arial, Helvetica, sans-serif;
    outline: 0;
  }
  .ant-pagination-prev button,
  .ant-pagination-next button {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    user-select: none;
  }
  .ant-pagination-prev:hover button,
  .ant-pagination-next:hover button {
    border-color: #40a9ff;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: none;
    transition: all 0.3s;
  }
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #1890ff;
    border-color: #1890ff;
  }
  .ant-pagination-disabled,
  .ant-pagination-disabled:hover,
  .ant-pagination-disabled:focus {
    cursor: not-allowed;
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-pagination-slash {
    margin: 0 10px 0 5px;
  }
  .ant-pagination-options {
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle;
  }
  @media all and (-ms-high-contrast: none) {
    .ant-pagination-options *::-ms-backdrop,
    .ant-pagination-options {
      vertical-align: top;
    }
  }
  .ant-pagination-options-size-changer.ant-select {
    display: inline-block;
    width: auto;
    margin-right: 8px;
  }
  .ant-pagination-options-quick-jumper {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    vertical-align: top;
  }
  .ant-pagination-options-quick-jumper input {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    width: 50px;
    margin: 0 8px;
  }
  .ant-pagination-options-quick-jumper input::-moz-placeholder {
    opacity: 1;
  }
  .ant-pagination-options-quick-jumper input::placeholder {
    color: #bfbfbf;
  }
  .ant-pagination-options-quick-jumper input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-pagination-options-quick-jumper input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-pagination-options-quick-jumper input:focus,
  .ant-pagination-options-quick-jumper input-focused {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-pagination-options-quick-jumper input-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-pagination-options-quick-jumper input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-pagination-options-quick-jumper input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-pagination-options-quick-jumper input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-pagination-options-quick-jumper input-borderless,
  .ant-pagination-options-quick-jumper input-borderless:hover,
  .ant-pagination-options-quick-jumper input-borderless:focus,
  .ant-pagination-options-quick-jumper input-borderless-focused,
  .ant-pagination-options-quick-jumper input-borderless-disabled,
  .ant-pagination-options-quick-jumper input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  textarea.ant-pagination-options-quick-jumper input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .ant-pagination-options-quick-jumper input-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-pagination-options-quick-jumper input-sm {
    padding: 0px 7px;
  }
  .ant-pagination-simple .ant-pagination-prev,
  .ant-pagination-simple .ant-pagination-next {
    height: 24px;
    line-height: 24px;
    vertical-align: top;
  }
  .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
    height: 24px;
    background-color: transparent;
    border: 0;
  }
  .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
  .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination-simple .ant-pagination-simple-pager {
    display: inline-block;
    height: 24px;
    margin-right: 8px;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input {
    box-sizing: border-box;
    height: 100%;
    margin-right: 8px;
    padding: 0 6px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: none;
    transition: border-color 0.3s;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input:hover {
    border-color: #1890ff;
  }
  .ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-pagination.mini .ant-pagination-total-text,
  .ant-pagination.mini .ant-pagination-simple-pager {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-item {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 22px;
  }
  .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
    background: transparent;
    border-color: transparent;
  }
  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    min-width: 24px;
    height: 24px;
    margin: 0;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
    background: transparent;
    border-color: transparent;
  }
  .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
  .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-jump-prev,
  .ant-pagination.mini .ant-pagination-jump-next {
    height: 24px;
    margin-right: 0;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-options {
    margin-left: 2px;
  }
  .ant-pagination.mini .ant-pagination-options-size-changer {
    top: 0px;
  }
  .ant-pagination.mini .ant-pagination-options-quick-jumper {
    height: 24px;
    line-height: 24px;
  }
  .ant-pagination.mini .ant-pagination-options-quick-jumper input {
    padding: 0px 7px;
    width: 44px;
  }
  .ant-pagination.ant-pagination-disabled {
    cursor: not-allowed;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item {
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item a {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    border: none;
    cursor: not-allowed;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
    background: #dbdbdb;
    border-color: transparent;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
    color: #fff;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed;
  }
  .ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
    background: transparent;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
    opacity: 0;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
    opacity: 1;
  }
  .ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
    color: rgba(0, 0, 0, 0.25);
  }
  @media only screen and (max-width: 992px) {
    .ant-pagination-item-after-jump-prev,
    .ant-pagination-item-before-jump-next {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    .ant-pagination-options {
      display: none;
    }
  }
  .ant-pagination-rtl {
    direction: rtl;
  }
  .ant-pagination-rtl .ant-pagination-total-text {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-pagination-rtl .ant-pagination-item {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-pagination-rtl .ant-pagination-prev,
  .ant-pagination-rtl .ant-pagination-jump-prev,
  .ant-pagination-rtl .ant-pagination-jump-next {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-pagination-rtl .ant-pagination-slash {
    margin: 0 5px 0 10px;
  }
  .ant-pagination-rtl .ant-pagination-options {
    margin-right: 16px;
    margin-left: 0;
  }
  .ant-pagination-rtl .ant-pagination-options-size-changer.ant-select {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
    margin-right: 0;
    margin-left: 8px;
  }
  .ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
    margin-right: 2px;
    margin-left: 0;
  }
`

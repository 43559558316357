import React, { FC } from 'react'
import { BackTop as ANTDBackTop } from 'antd'
import { BackTopProps } from 'antd/lib/back-top'
import { BackTopStyle } from 'src/@legacy/@core/style/styled/antd/BackTop.style'

export const BackTop: FC<BackTopProps & React.RefAttributes<HTMLDivElement>> = (props) => (
  <>
    <style jsx global>
      {BackTopStyle}
    </style>
    <ANTDBackTop {...props} />
  </>
)

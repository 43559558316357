import React from 'react'
import { Checkbox as ANTDCheckbox } from 'antd'
import { CheckBoxStyle } from 'src/@legacy/@core/style/styled/antd/Checkbox.style'

export class Checkbox extends ANTDCheckbox {
  render() {
    return (
      <>
        <style jsx global>
          {CheckBoxStyle}
        </style>
        <ANTDCheckbox {...this.props} />
      </>
    )
  }
}

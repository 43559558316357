import React, { ReactElement } from 'react'
import { Table as ANTDTable } from 'antd'
import ANTDConfigProvider from 'antd/lib/config-provider'
import { TableProps } from 'antd/lib/table'
import { TableStyle } from 'src/@legacy/@core/style/styled/antd/Table.style'

export const Table = <RecordType extends object = any>(props: TableProps<RecordType>): ReactElement<any, any> | null => (
  <>
    <style jsx global>
      {TableStyle}
    </style>
    <ANTDTable {...props} />
  </>
)
export const TableSummary = ANTDTable.Summary
export const ConfigProvider = ANTDConfigProvider

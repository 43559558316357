import React, { FC } from 'react'
import { Tabs as ANTDTabs } from 'antd'
import { TabsProps } from 'antd/lib/tabs'
import { TabsStyle } from 'src/@legacy/@core/style/styled/antd/Tabs.style'

export const Tabs: FC<TabsProps> & { TabPane: typeof ANTDTabs.TabPane } = (props) => (
  <>
    <style jsx global>
      {TabsStyle}
    </style>
    <ANTDTabs {...props} />
  </>
)
Tabs.TabPane = ANTDTabs.TabPane

import React, { FC } from 'react'
import { Pagination as ANTDPagination } from 'antd'
import { PaginationProps } from 'antd/lib/pagination'
import { PaginationStyle } from 'src/@legacy/@core/style/styled/antd/Pagination.style'

export const Pagination: FC<PaginationProps> = (props) => (
  <>
    <style jsx global>
      {PaginationStyle}
    </style>
    <ANTDPagination {...props} />
  </>
)

import React, { FC } from 'react'
import { Spin as ANTDSpin } from 'antd'
import { SpinProps } from 'antd/lib/spin'
import { SpinStyle } from 'src/@legacy/@core/style/styled/antd/Spin.style'

export const Spin: FC<SpinProps> = (props) => (
  <>
    <style jsx global>
      {SpinStyle}
    </style>
    <ANTDSpin {...props} />
  </>
)

import React, { FC } from 'react'
import { Switch as ANTDSwitch } from 'antd'
import { SwitchProps } from 'antd/lib/switch'
import { SwitchStyle } from 'src/@legacy/@core/style/styled/antd/Switch.style'

export const Switch: FC<SwitchProps> = (props) => (
  <>
    <style jsx global>
      {SwitchStyle}
    </style>
    <ANTDSwitch {...props} />
  </>
)
